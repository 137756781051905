<template>
  <div
    class="bg-yellow w-full min-h-screen flex flex-col justify-start py-12 px-44 relative"
  >
    <router-link
      to="/dashboard/retos-dcm-p03"
      class="absolute underline top-4 left-4"
      >Atrás</router-link
    >
    <h1 class="text-white text-5xl">P03</h1>
    <div class="w-full mt-5 flex flex-col justify-around">
      <p class="font-bold text-xl">
        Nombre:
        <span class="text-xl font-normal">
          {{ user.name }}
        </span>
      </p>

      <p class="font-bold text-xl">
        Usuario: <router-link :to="`/user/${userId}`">{{ userId }}</router-link>
      </p>

      <p class="font-bold text-xl">
        Compañía:
        <span class="text-xl font-normal">
          {{ company?.name ?? "EMPRESA ELIMINADA" }}
        </span>
      </p>

      <p class="font-bold text-xl">
        Ciudad:
        <span class="text-xl font-normal">
          {{ user.city }}
        </span>
      </p>
      <div class="w-full flex justify-center">
        <div class="w-[90%] bg-white border border-black rounded-xl py-2 px-5">
          <p class="w-3/5 text-3xl font-bold">Estado de la Revisión</p>
          <div class="w-full flex justify-around">
            <p class="text-base font-bold">
              Total Facturas : {{ totalInvoices }}
            </p>
            <p class="text-base font-bold">
              Facturas Aprobadas : {{ invoicesAccept }}
            </p>
            <p class="text-base font-bold">
              Aceptación Video :
              {{ challengesUserP03.video ? "Aceptado" : "Pendiente" }}
            </p>
            <p class="ext-base font-bold">Aceptación del Reto : {{ estado }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full px-52 mt-5">
      <div
        v-if="!challengesUserP03.video"
        class="w-full flex items-center justify-around bg-white border border-black py-2 px-4 rounded-xl mb-4"
      >
        <p class="mt-2">Presione el Botón para aceptar el Video</p>
        <button
          v-if="true"
          class="bg-red-400 text-white uppercase rounded-lg p-2 font-bold"
          @click="acceptVideo()"
        >
          Aceptar Video
        </button>
      </div>
      <v-data-table
        :headers="headers"
        :items="invoices"
        :items-per-page="10"
        class="w-full"
        :loading="loadingData"
        loading-text="Cargando fotos"
      >
        <template v-slot:[`item.url`]="{ item }">
          <div class="flex flex-col items-start">
            <a :href="item.url" target="_blank">Ver Foto</a>
          </div>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          <p
            class="text-green-700 font-bold"
            v-if="item.isValid && item.isReviewed"
          >
            Aceptado
          </p>
          <p
            class="text-red-700 font-bold"
            v-if="!item.isValid && item.isReviewed"
          >
            Rechazado
          </p>
          <p
            class="text-yellow-600 font-bold"
            v-if="!item.isValid && !item.isReviewed"
          >
            Por revisar
          </p>
        </template>

        <template v-slot:[`item.accept`]="{ item }">
          <button
            v-if="!item.isReviewed"
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="acceptInvoice(item)"
          >
            Aceptar
          </button>
        </template>

        <template v-slot:[`item.reject`]="{ item }">
          <button
            v-if="!item.isReviewed"
            class="bg-yellow text-white uppercase rounded-lg p-2 font-bold"
            @click="rejectInvoice(item)"
          >
            Rechazar
          </button>
        </template>
      </v-data-table>
    </div>
    <div class="w-full flex flex-col items-center mt-8">
      <!--v-if="
          challengesUserP03.isCompleted && 
          !challengesUserP03.isReviewed && 
          invoicesAccept===30 &&
          challengesUserP03.video"-->
      <div class="w-1/2 flex justify-around">
        <button
          class="bg-red-700 text-white uppercase rounded-lg p-2 font-bold"
          @click="acceptChallenge()"
        >
          Aceptar Reto
        </button>
        <button
          class="bg-red-700 text-white uppercase rounded-lg p-2 font-bold"
          @click="rejectChallenge()"
        >
          Rechazar Reto
        </button>
      </div>
    </div>
    <Loader :isLoading="loadingData" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      headers: [
        //{ text: "Factura", value: "invoice" },
        { text: "Foto", value: "url" },
        { text: "Estado", value: "state" },
        { text: "Aceptar", value: "accept" },
        { text: "Rechazar", value: "reject" },
      ],
      user: { name: "", companyName: "", city: "" },
      company: null,
      userId: null,
      invoices: [],
      challengesUserP03: [],
      challengeUserId: null,
      loadingData: true,
      isLoading: true,
      totalInvoices: 0,
      invoicesAccept: 0,
      invoicesReject: 0,
      estado: "",
    };
  },
  async mounted() {
    const res2 = await this["challenges/getDCMChallengesUserP03"](
      this.$route.params.id
    );
    if (res2.success) {
      this.challengesUserP03 = res2.challengesUserP03;
      this.invoices = res2.invoices;
      this.userId = res2.challengesUserP03.uid;
      this.challengeUserId = this.$route.params.id;
    }
    const res = await this["user/searchUser"]({
      searchTerm: "id",
      search: this.userId,
    });
    if (res.success) {
      this.user = res.user;
      const companyData = await this["user/searchCompany"](res.user.companyId);
      this.company = companyData;
    }
    let countAccept = 0;
    let countReject = 0;
    this.invoices.map((element) => {
      if (element.isReviewed && element.isValid) {
        countAccept += 1;
      }
      if (element.isReviewed && !element.isValid) {
        countReject += 1;
      }
    });

    if (this.challengesUserP03.isValid && this.challengesUserP03.isReviewed) {
      this.estado = "Aceptado";
    }
    if (!this.challengesUserP03.isValid && this.challengesUserP03.isReviewed) {
      this.estado = "Rechazado";
    }
    if (!this.challengesUserP03.isValid && !this.challengesUserP03.isReviewed) {
      this.estado = "Pendiente";
    }

    this.invoicesAccept = countAccept;
    this.invoicesReject = countReject;
    this.totalInvoices = this.invoices.length;
    this.loadingData = false;
  },
  methods: {
    ...mapActions([
      "user/searchUser",
      "user/searchCompany",
      "challenges/getDCMChallengesUserP03",
      "challenges/updateInvoiceP03",
      "challenges/acceptChallengeP03",
      "challenges/rejectChallengeP03",
      "challenges/acceptVideoChallengeP03",
      "challenges/rejectInvoiceP03",
    ]),

    async acceptInvoice(item) {
      this.loadingData = true;
      //console.log(item);
      let indexPos = 0;
      let indexInvoice;
      this.invoices.map((element) => {
        if (element.invoice === item.invoice) {
          indexInvoice = indexPos;
        }
        indexPos += 1;
      });

      this.invoices[indexInvoice].isReviewed = true;
      this.invoices[indexInvoice].isValid = true;
      this.invoices[indexInvoice].updateAt = Date.now();
      await this["challenges/updateInvoiceP03"]({
        uid: this.userId,
        numberInvoice: item.invoice,
        array: this.invoices,
        challengeUserId: this.challengeUserId,
      });

      let countAccept = 0;
      let countReject = 0;
      this.invoices.map((element) => {
        if (element.isReviewed && element.isValid) {
          countAccept += 1;
        }
        if (element.isReviewed && !element.isValid) {
          countReject += 1;
        }
      });
      this.invoicesAccept = countAccept;
      this.invoicesReject = countReject;
      this.loadingData = false;
    },

    async rejectInvoice(item) {
      this.loadingData = true;
      let indexPos = 0;
      let indexInvoice;
      this.invoices.map((element) => {
        if (element.invoice === item.invoice) {
          indexInvoice = indexPos;
        }
        indexPos += 1;
      });

      this.invoices.splice(indexInvoice, 1); //Eliminando

      await this["challenges/rejectInvoiceP03"]({
        array: this.invoices,
        challengeUserId: this.challengeUserId,
      });
      let countAccept = 0;
      let countReject = 0;
      this.invoices.map((element) => {
        if (element.isReviewed && element.isValid) {
          countAccept += 1;
        }
        if (element.isReviewed && !element.isValid) {
          countReject += 1;
        }
      });
      this.invoicesAccept = countAccept;
      this.invoicesReject = countReject;
      this.loadingData = false;
    },

    async acceptChallenge() {
      let message = "¿Dese aceptar el reto de " + this.user.name + " ?";
      let result = window.confirm(message);

      if (result === true) {
        this.loadingData = true;
        await this["challenges/acceptChallengeP03"]({
          challengeUserId: this.challengeUserId,
        });
        location.reload();
      }
    },
    async rejectChallenge() {
      let message = "¿Dese Rechazar el reto de " + this.user.name + " ?";
      let result = window.confirm(message);

      if (result === true) {
        this.loadingData = true;
        await this["challenges/rejectChallengeP03"]({
          challengeUserId: this.challengeUserId,
        });
        location.reload();
      }
    },

    async acceptVideo() {
      this.loadingData = true;
      await this["challenges/acceptVideoChallengeP03"]({
        challengeUserId: this.challengeUserId,
      });
      location.reload();
    },
  },
};
</script>
